import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/homePage";
import PageNotFound from "./features/utils/PageNotFound";
import LoginPage from "./features/authentication/loginPage";
import ProfilePage from "./features/userProfile/profilePage";
import PrivateRoutes from "./features/utils/PrivateRoutes";
import LoggedInRoutes from "./features/utils/LoggedInRoutes";
import DashboardPage from "./features/superApi/dashboardPage";
import SettingsPage from "./features/userProfile/settingsPage";
import ServiceCards from "./features/services/servicesPage";
import CleanImages from "./features/services/litholens/clean_images/CleanImagesPage";
import LitholensFlowPage from "./features/services/litholens/litholensFlowPage";
import Talim from "./features/services/litholens/talim/Talim";
import XRD from "./features/services/litholens/xrd/XRD";
import SMS from "./features/services/litholens/sms/SMS";
import ServicsPage from "./features/services/servicesPage";
import ProjectsPage from "./features/projects/projectsPage";
import ProjectDetailsPage from "./features/projects/projectDetailsPage";

const BaseRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route element={<LoggedInRoutes />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/services" element={<ServicsPage />} />
          <Route path="/services" element={<ServiceCards />} />
          <Route path="/services/litholens" element={<LitholensFlowPage />} />
          <Route path="/services/talim" element={<Talim />} />
          <Route path="/services/xrd" element={<XRD />} />
          <Route path="/services/sms" element={<SMS />} />
          <Route
            path="/:projectName/service/clean-images"
            element={<CleanImages />}
          />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route
            path="/projects/:projectName"
            element={<ProjectDetailsPage />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default BaseRouter;
