import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CardActions,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

type Service = {
  title: string;
  route: string;
  imageUrl: string;
};

const services = [
  {
    title: "Talim",
    route: "talim",
    imageUrl: "https://via.placeholder.com/300x300?text=Talim",
  },
  {
    title: "XRD Tool",
    route: "xrd",
    imageUrl: "https://via.placeholder.com/300x300?text=XRD",
  },
  {
    title: "Litholens",
    route: "litholens",
    imageUrl: "https://via.placeholder.com/300x300?text=Litholens",
  },
  {
    title: "Tesselation",
    route: "tesselation",
    imageUrl: "https://via.placeholder.com/300x300?text=Tesselation",
  },
  {
    title: "Stoichiometric Mineralogy Services",
    route: "sms",
    imageUrl: "https://via.placeholder.com/300x300?text=SMS",
  },
];

const ServiceCard: React.FC<{ service: Service }> = ({ service }) => {
  const { projectName } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/services/${service.route}`);
  };

  return (
    <Card sx={{ maxWidth: 300, m: 1, height: "100%" }}>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          height="140"
          image={service.imageUrl}
          alt={service.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {service.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={handleClick}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

const ServicsPage: React.FC = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "16px",
        padding: "16px",
      }}
    >
      {services.map((service) => (
        <ServiceCard key={service.route} service={service} />
      ))}
    </div>
  );
};

export default ServicsPage;
