import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Tabs, Tab } from "@mui/material";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import S3FileUploader from "../../../../components/S3/S3FileUploader";
import S3ModelUploader from "../../../../components/S3/S3ModelUploader";
import S3CsvViewer from "./S3CsvViewer";
import MergePage from "./merge_components/mergePage";
import EDAPage from "./eda_components/edaPage";
import Preprocessing from "./Preprocessing";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProjectsSelector from "../../../projects/projectSelector";
import CleaningPage from "./cleaning_components/cleaningPage";
import InferencePage from "./inference_components/inferencePage";
import TrainPage from "./train_components/trainPage";
import { useGetProjectsQuery } from "../../../api/endpoints/projectsApi";
import { CLUSTERING_MODELS_FOLDER_PATH, ENVIRONMENT } from "../../../../config";
import config from "../../../../config";

const steps: string[] = [
  "Upload Data",
  "Set MetaData",
  "Clean",
  "Merge",
  "EDA",
  "Preprocess",
  "Apply ML Models",
];

const Talim: React.FC = () => {
  const talim = useAppSelector((state) => state.talim.talimSlice);
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAppSelector((state) => state.auth);
  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";

  const [activeStep, setActiveStep] = React.useState<number>(
    parseInt(new URLSearchParams(location.search).get("step")!) || 0
  );

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const user = useAppSelector((state) =>
    state.auth.isAuthenticated ? state.auth.user?.firstName : "guest@"
  );

  // const bucketName = "superapi-dashboard";
  const bucketName = config[ENVIRONMENT].talim_s3_bucket;
  // const pathPrefix = "talim";
  const userFolder = auth.user?.username
    ? auth.user?.username.lastIndexOf("@") > -1
      ? auth.user?.username.substring(0, auth.user?.username.lastIndexOf("@"))
      : auth.user?.username
    : "guest";
  const [pathPrefix, setPathPrefix] = React.useState(
    // "general/" + user?.substring(0, user.indexOf("@")) + "/talim"
    "general" + userFolder + "/talim"
  );

  console.log(pathPrefix);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
    navigate(`?step=${step}`);
  };

  React.useEffect(() => {
    const userFolder = auth.user?.username
      ? auth.user?.username.lastIndexOf("@") > -1
        ? auth.user?.username.substring(0, auth.user?.username.lastIndexOf("@"))
        : auth.user?.username
      : "guest";
    setPathPrefix(
      projects.selectedProject
        ? company + "/" + project + "/talim"
        : "general/" + userFolder + "/talim"
      // : "general/" + user?.substring(0, user.indexOf("@")) + "/talim"
    );
    console.log(pathPrefix);
  }, [projects.selectedProject]);
  // const {
  //   data: projectsData,
  //   error: projectsError,
  //   isLoading,
  //   isFetching,
  //   refetch,
  // } = useGetProjectsQuery({ companyName: "goldspot" });

  // React.useEffect(() => {
  //   const {
  //     data: projectsData,
  //     error: projectsError,
  //     isLoading,
  //     isFetching,
  //     refetch,
  //   } = useGetProjectsQuery();
  // }, [pathPrefix]);

  return (
    <div>
      <ProjectsSelector />
      <Box>
        <div
          style={{
            border: "1px solid #ccc",
            padding: "20px",
            borderRadius: "4px",
          }}
        >
          <Stepper
            nonLinear
            activeStep={activeStep}
            orientation={matches ? "vertical" : "horizontal"}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div>
          <React.Fragment>
            {activeStep === 0 && (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <S3FileUploader
                      bucketName={bucketName}
                      pathPrefix={pathPrefix + "/data"}
                      allowedExtensions={["csv"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <S3ModelUploader
                      bucketName={bucketName}
                      pathPrefix={pathPrefix + "/models"}
                      allowedExtensions={["pkl", "json"]}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {activeStep === 1 && (
              <S3CsvViewer bucketName={bucketName} pathPrefix={pathPrefix} />
            )}
            {activeStep === 2 && (
              <CleaningPage bucketName={bucketName} pathPrefix={pathPrefix} />
            )}
            {activeStep === 3 && (
              <MergePage bucketName={bucketName} pathPrefix={pathPrefix} />
            )}
            {activeStep === 4 && (
              <EDAPage bucketName={bucketName} pathPrefix={pathPrefix} />
            )}
            {activeStep === 5 && (
              <Preprocessing bucketName={bucketName} pathPrefix={pathPrefix} />
            )}
            {activeStep === 6 && (
              <div>
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={activeTab}
                  onChange={handleChange}
                  aria-label="file tabs"
                  sx={{
                    backgroundColor: "white", // Light teal background for the whole tabs bar
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow under the tabs bar
                    "& .MuiTabs-flexContainer": {
                      gap: "10px", // Adds space between each tab/button
                    },
                  }}
                >
                  <Tab
                    key="training"
                    label="Train a New Model."
                    id="training"
                    aria-controls={`tabpanel-training`}
                  />
                  <Tab
                    key="inference"
                    label="Apply a Trained Model."
                    id="inference"
                    aria-controls={`tabpanel-inference`}
                  />
                </Tabs>
                <div hidden={activeTab !== 0}>
                  <TrainPage bucketName={bucketName} pathPrefix={pathPrefix} />
                </div>
                <div hidden={activeTab !== 1}>
                  <InferencePage
                    bucketName={bucketName}
                    pathPrefix={pathPrefix}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        </div>
      </Box>
    </div>
  );
};

export default Talim;
