import React, { useEffect, useState } from "react";
import { getFilesWithMetaData } from "../talimUtils";
import FEngFileSelection from "./fengFileSelection";
import FEngFileTabs from "./fengFileTabs";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFiles } from "./talimFengSlice";
import FEngRequest from "./fengRequest";
import { Button } from "@mui/material";
import FEngResponseViewer from "./fengResponseViewer";

interface FEngPageProps {
  bucketName: string;
  pathPrefix: string;
}

const FEngPage: React.FC<FEngPageProps> = ({ bucketName, pathPrefix }) => {
  const talim_feng = useAppSelector((state) => state.talim.talimFengSlice);
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadFiles = async () => {
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
    };
    loadFiles();
  }, [pathPrefix]);

  const [viewJobs, setViewJobs] = React.useState(false);

  const handleClick = () => {
    setViewJobs(!viewJobs);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClick}>
          {viewJobs ? "Request a New Job" : "View Completed Jobs"}
        </Button>
      </div>
      <div key={"main"} hidden={viewJobs}>
        <FEngFileSelection bucketName={bucketName} pathPrefix={pathPrefix} />
        <FEngFileTabs bucketName={bucketName} />
        <FEngRequest bucketName={bucketName} pathPrefix={pathPrefix} />
      </div>
      <div key={"dialog"} hidden={!viewJobs}>
        <FEngResponseViewer
          refresh=""
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          serviceName="feng"
        ></FEngResponseViewer>
      </div>
    </div>
  );
};

export default FEngPage;
