import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Autocomplete,
  FormControl,
  Button,
} from "@mui/material";
import {
  S3File,
  getFileInfo,
  getTableDataFromCsv,
  readJsonFromS3,
} from "../../../../../components/S3/S3Utils";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFilesInfo, setSelectedReferenceFile } from "./talimCleaningSlice";
import {
  getDefaultOptionsForColumns,
  getDefaultDetectionOptionsForColumns,
  getDefaultHighDetectionValuesOptionsForColumns,
  getDefaultLowDetectionValuesOptionsForColumns,
} from "./ColumnActions";

interface CleaningFileSelectionProps {
  bucketName: string;
  pathPrefix: string;
}

const CleaningFileSelection: React.FC<CleaningFileSelectionProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_cleaning = useAppSelector(
    (state) => state.talim.talimCleaningSlice
  );
  const dispatch = useAppDispatch();
  const allFileKeys = [talim_cleaning.selectedReferenceFile];

  const [refreshToggle, setRefreshToggle] = useState(false);

  const handleRefresh = () => {
    setRefreshToggle((prev) => !prev); // Toggle refreshToggle state
  };

  useEffect(() => {
    allFileKeys.forEach(async (fileKey) => {
      if (fileKey && !talim_cleaning.filesInfo[fileKey]) {
        const metaDataKey = fileKey
          .replace("data", "metaData")
          .replace(".csv", ".json");
        try {
          const metaData = await readJsonFromS3(bucketName, metaDataKey);
          const { columns, data } = await getTableDataFromCsv(
            bucketName,
            fileKey
          );
          console.log(metaData);
          dispatch(
            setFilesInfo({
              key: fileKey,
              info: {
                fileKey: fileKey,
                metaData: metaData,
                tableData: { columns: columns, data: data },
                columnActions: getDefaultOptionsForColumns(data, metaData),
                lowLevelDetectionTypes: getDefaultDetectionOptionsForColumns(
                  data,
                  metaData
                ),
                highLevelDetectionTypes: getDefaultDetectionOptionsForColumns(
                  data,
                  metaData
                ),
                lowLevelDetectionValues:
                  getDefaultLowDetectionValuesOptionsForColumns(data),
                highLevelDetectionValues:
                  getDefaultHighDetectionValuesOptionsForColumns(data),
              },
            })
          );
          console.log(metaData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });
  }, [talim_cleaning.selectedReferenceFile, bucketName, refreshToggle]);

  const handleReferenceFileChange = (
    event: React.ChangeEvent<{}>,
    value: S3File | null
  ) => {
    const newReferenceFileKey = value ? value.Key : "";

    dispatch(setSelectedReferenceFile({ key: newReferenceFileKey }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleRefresh}
          style={{ width: "300px" }}
        >
          Click to refresh (if not getting the missing values table)
        </Button>
      </div>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          options={talim_cleaning.files}
          getOptionLabel={(option) => getFileInfo(option.Key).baseName}
          value={
            talim_cleaning.files.find(
              (file) => file.Key === talim_cleaning.selectedReferenceFile
            ) || null
          }
          onChange={handleReferenceFileChange}
          renderInput={(params) => (
            <TextField {...params} label="Dataset File" variant="outlined" />
          )}
          fullWidth
        />
      </FormControl>
    </div>
  );
};
export default CleaningFileSelection;
