import React, { useEffect, useState } from "react";
import { getFilesWithMetaData } from "../talimUtils";
import CleaningFileSelection from "./cleaningFileSelection";
import CleaningFileTabs from "./cleaningFileTabs";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFiles } from "./talimCleaningSlice";
import CleaningRequest from "./cleaningRequest";
import { Button } from "@mui/material";
import S3ResponseViewer from "../../../../../components/S3/S3ResponseViewer";
import CleaningResponseViewer from "./cleaningResponseViewer";

interface CleaningPageProps {
  bucketName: string;
  pathPrefix: string;
}

const CleaningPage: React.FC<CleaningPageProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_cleaning = useAppSelector(
    (state) => state.talim.talimCleaningSlice
  );
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadFiles = async () => {
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
    };
    loadFiles();
  }, [pathPrefix]);

  const [viewJobs, setViewJobs] = React.useState(false);

  const handleClick = () => {
    setViewJobs(!viewJobs);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClick}>
          {viewJobs ? "Request a New Job" : "View Completed Jobs"}
        </Button>
      </div>
      <div key={"main"} hidden={viewJobs}>
        <CleaningFileSelection
          bucketName={bucketName}
          pathPrefix={pathPrefix}
        />
        <CleaningFileTabs bucketName={bucketName} />
        <CleaningRequest bucketName={bucketName} pathPrefix={pathPrefix} />
      </div>
      <div key={"dialog"} hidden={!viewJobs}>
        <CleaningResponseViewer
          refresh=""
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          serviceName="dim"
        ></CleaningResponseViewer>
      </div>
    </div>
  );
};

export default CleaningPage;
