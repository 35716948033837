import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import {
  defaultActionPolicyDescription,
  actionsDescription,
  lowLevelDetectionDescriptions,
  highLevelDetectionDescriptions,
  knnClustersDescription,
  invalidStringsDescription,
} from "./cleaningDescriptions";
import { setState } from "./talimCleaningSlice";

const CleaningConfigParams: React.FC = () => {
  const talim_dim = useAppSelector((state) => state.talim.talimCleaningSlice);

  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [dialogBoxKey, setDialogBoxKey] = useState<string | null>(null);

  const [selectAllFeaturesForImputation, setSelectAllFeaturesForImputation] =
    useState(true);

  const dispatch = useAppDispatch();

  const maxDimensions =
    talim_dim.filesInfo[talim_dim.selectedReferenceFile]?.tableData.columns
      .length - 3;

  // --------------------------------------------------------------------------------------------------
  // Shared Parameters

  const handleKnnImputationClustersChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "knn_clusters",
          value: parseInt(newValue),
        })
      );
    }
  };

  const handleImputationInputFeaturesChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string[] | null
  ) => {
    dispatch(
      setState({
        key: "imputation_input_features",
        value: newValue ? (newValue.length > 0 ? newValue : null) : null,
      })
    );
  };

  const handleRegisterToLithoLensChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_litholens",
        value: checked,
      })
    );
  };

  const handleRegisterToLogsApiChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_logsapi",
        value: checked,
      })
    );
  };

  const handleNotificationEmailChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "notification_email",
        value: event.target.value as string,
      })
    );
  };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "s3_data_save_name",
        value: event.target.value as string,
      })
    );
  };

  const handleLogDescriptiveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "descriptive_log_name",
        value: event.target.value as string,
      })
    );
  };

  const [inputValue, setInputValue] = useState<string>("");
  const [selectedStrings, setSelectedStrings] = useState<string[]>(
    talim_dim.strings_to_remove
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAddString = () => {
    if (inputValue && !selectedStrings.includes(inputValue)) {
      setSelectedStrings([...selectedStrings, inputValue]);
      setInputValue(""); // Clear the input field after adding
      dispatch(
        setState({
          key: "strings_to_remove",
          value: selectedStrings as string[],
        })
      );
    }
  };

  const handleDeleteString = (stringToDelete: string) => {
    setSelectedStrings(selectedStrings.filter((str) => str !== stringToDelete));
    dispatch(
      setState({
        key: "strings_to_remove",
        value: selectedStrings as string[],
      })
    );
  };

  // Dialog Box

  const handleClickOpen = (key: string) => {
    setDialogBoxKey(key);
    setOpenDialogBox(true);
  };

  const handleClose = () => {
    setDialogBoxKey(null);
    setOpenDialogBox(false);
  };

  const getDialogText = (key: string) => {
    switch (key) {
      case "defaultActionPolicyDescription":
        return defaultActionPolicyDescription;
      case "actionsDescription":
        return actionsDescription;
      case "lowLevelDetectionDescriptions":
        return lowLevelDetectionDescriptions;
      case "highLevelDetectionDescriptions":
        return highLevelDetectionDescriptions;
      case "knnClustersDescription":
        return knnClustersDescription;
      case "invalidStringsDescription":
        return invalidStringsDescription;
      default:
        return "No info available!";
    }
  };

  //

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Set Cleaning API Parameters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <FormControl variant="standard">
                <TextField
                  label="Number of Neighbors (for KNN-Imputation)"
                  variant="outlined"
                  type="number"
                  disabled={
                    talim_dim?.filesInfo[talim_dim.selectedReferenceFile]
                      ?.columnActions &&
                    (Object.values(
                      talim_dim?.filesInfo[talim_dim.selectedReferenceFile]
                        ?.columnActions
                    ).includes("knn_imputation")
                      ? false
                      : true)
                  }
                  defaultValue={talim_dim.knn_clusters}
                  onChange={handleKnnImputationClustersChange}
                  inputProps={{
                    // Optional: Define min and max values
                    min: 2, // Minimum value
                    max: maxDimensions ? maxDimensions : 2, // Maximum value
                    step: 1, // Step increment for keyboard arrows
                  }}
                />
              </FormControl>
              <Button
                variant="contained"
                onClick={() => handleClickOpen("knnClustersDescription")}
                size="small"
                sx={{
                  ml: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <HelpIcon />
              </Button>
            </Box>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                multiple
                options={
                  talim_dim.filesInfo[
                    talim_dim.selectedReferenceFile
                  ]?.tableData.columns.filter(
                    (element) =>
                      !Object.values(
                        talim_dim.filesInfo[talim_dim.selectedReferenceFile]
                          .metaData
                      ).includes(element)
                  ) || []
                }
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={talim_dim.imputation_input_features || []}
                onChange={handleImputationInputFeaturesChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Imputation Input Features"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box sx={{ maxWidth: 500, mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Invalid Strings
            </Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <TextField
                label="Add a string"
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddString}
                sx={{ ml: 2 }}
              >
                Add
              </Button>
              <Button
                variant="contained"
                onClick={() => handleClickOpen("invalidStringsDescription")}
                size="small"
                sx={{
                  ml: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <HelpIcon />
              </Button>
            </Box>
            {selectedStrings.length > 0 && (
              <div>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {selectedStrings.map((str) => (
                    <Chip
                      key={str}
                      label={str}
                      onDelete={() => handleDeleteString(str)}
                      color="primary"
                    />
                  ))}
                </Box>
              </div>
            )}
          </Box>
          <Typography>Output Registration Options</Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControlLabel
              control={
                <Switch
                  value="register_to_litholens"
                  checked={talim_dim.register_to_litholens}
                  onChange={handleRegisterToLithoLensChange}
                />
              }
              label="LithoLens"
            />
            <FormControlLabel
              control={
                <Switch
                  value="register_to_logsapi"
                  checked={talim_dim.register_to_logsapi}
                  onChange={handleRegisterToLogsApiChange}
                />
              }
              label="LogsAPI"
            />
          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="notification-email"
              label="Notification E-Mail"
              defaultValue=""
              onChange={handleNotificationEmailChange}
            />
            <TextField
              id="s3-save-name"
              label="S3 Save Name"
              defaultValue={talim_dim.s3_data_save_name}
              error={
                talim_dim.s3_data_save_name === null ||
                talim_dim.s3_data_save_name === ""
              }
              onChange={handleS3SaveNameChange}
            />
            <TextField
              id="logs-descriptive-name"
              label="LogsAPI descriptive name"
              defaultValue=""
              onChange={handleLogDescriptiveNameChange}
              disabled={!talim_dim.register_to_logsapi}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Dialog open={openDialogBox} onClick={handleClose}>
        <DialogContent>
          <DialogContentText>
            {getDialogText(dialogBoxKey || "")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CleaningConfigParams;
