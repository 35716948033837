import AWS from "aws-sdk";
import { parse } from "papaparse";
import { Buffer } from "buffer";
import archiver from "archiver";
import fs from "fs";
import JSZip from "jszip";
import { saveAs } from "file-saver";
// ------------------------------------------------------------------------------------
interface UploadProgress {
  [key: string]: number;
}

// ------------------------------------------------------------------------------------
export interface S3File {
  Key: string;
  Size: number;
  Type: string;
}

// ------------------------------------------------------------------------------------
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3();

// ------------------------------------------------------------------------------------
export const bytesToMB = (bytes: number): number => {
  return parseFloat((bytes / 1024 / 1024).toFixed(3));
};

// ------------------------------------------------------------------------------------
interface FileInfo {
  baseName: string;
  fileName: string;
  extension: string;
  pathPrefix: string;
}

export const getFileInfo = (fileKey: string): FileInfo => {
  const parts = fileKey.split("/");
  const baseName = parts.pop() ?? "";
  const extensionMatch = baseName.match(/(\.\w+)$/);
  const extension = extensionMatch ? extensionMatch[1] : "";
  const fileName = baseName.replace(extension, "");
  const pathPrefix = parts.join("/");

  return {
    baseName,
    fileName,
    extension,
    pathPrefix,
  };
};

// ------------------------------------------------------------------------------------
export const uploadFilesToS3 = async (
  files: File[],
  setUploadProgress: React.Dispatch<React.SetStateAction<UploadProgress>>,
  bucketName: string,
  pathPrefix: string
) => {
  const uploadPromises = files.map((file) => {
    const params = {
      Bucket: bucketName,
      Key: `${pathPrefix}/${file.name}`,
      Body: file,
    };

    return new AWS.S3.ManagedUpload({
      service: s3,
      params: params,
    })
      .on("httpUploadProgress", (progress: AWS.S3.ManagedUpload.Progress) => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: (progress.loaded / progress.total) * 100,
        }));
      })
      .promise();
  });

  try {
    await Promise.all(uploadPromises);
  } catch (error) {
    throw new Error("Upload failed: " + error);
  }
};

// ------------------------------------------------------------------------------------
export const fetchFiles = async (
  bucketName: string,
  pathPrefix: string,
  filterExtensions: string[] = []
): Promise<S3File[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: pathPrefix,
  };
  try {
    const data = await s3.listObjectsV2(params).promise();
    let files =
      data.Contents?.map((file) => ({
        Key: file.Key || "",
        Size: bytesToMB(file.Size || 0),
        Type: file.Key ? file.Key.split(".").pop() || "unknown" : "unknown",
      })) || [];

    // Filter files based on the extension types provided in filterExtensions
    if (filterExtensions.length > 0) {
      files = files.filter((file) => filterExtensions.includes(file.Type));
    }

    return files;
  } catch (error) {
    console.error("Error fetching files: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const fetchImmediateFiles = async (
  bucketName: string,
  pathPrefix: string,
  filterExtensions: string[] = []
): Promise<S3File[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: pathPrefix,
    Delimiter: "/", // Use delimiter to get only immediate level files
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    let files =
      data.Contents?.map((file) => ({
        Key: file.Key || "",
        Size: bytesToMB(file.Size || 0),
        Type: file.Key ? file.Key.split(".").pop() || "unknown" : "unknown",
      })) || [];

    // Filter files based on the extension types provided in filterExtensions
    if (filterExtensions.length > 0) {
      files = files.filter((file) => filterExtensions.includes(file.Type));
    }

    // Exclude folders by checking if the key ends with a slash
    files = files.filter((file) => !file.Key.endsWith("/"));

    return files;
  } catch (error) {
    console.error("Error fetching files: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const deleteFile = async (
  bucketName: string,
  key: string
): Promise<void> => {
  const params = { Bucket: bucketName, Key: key };
  try {
    await s3.deleteObject(params).promise();
  } catch (error) {
    console.error("Error deleting file: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const getDownloadUrl = async (
  bucketName: string,
  key: string
): Promise<string> => {
  const params = {
    Bucket: bucketName,
    Key: key,
    Expires: 60, // URL expires in 60 seconds
  };
  try {
    const url = await s3.getSignedUrlPromise("getObject", params);
    return url;
  } catch (error) {
    console.error("Error generating download URL: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const writeJsonToS3 = async (
  bucketName: string,
  key: string,
  jsonData: object
): Promise<void> => {
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: JSON.stringify(jsonData),
    ContentType: "application/json",
  };

  try {
    await s3.putObject(params).promise();
    console.log("JSON file uploaded successfully.");
  } catch (error) {
    console.error("Error uploading JSON file: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const writeImageToS3 = async (
  bucketName: string,
  key: string,
  bodyString: string,
  bodyType: string
): Promise<void> => {
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: Buffer.from(bodyString, "base64"),
    ContentType: bodyType,
  };

  try {
    await s3.putObject(params).promise();
    console.log("Image uploaded successfully.");
  } catch (error) {
    console.error("Error uploading image: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const readJsonFromS3 = async (
  bucketName: string,
  key: string
): Promise<any> => {
  const params = {
    Bucket: bucketName,
    Key: key,
  };

  try {
    const data = await s3.getObject(params).promise();
    if (data.Body) {
      return JSON.parse(data.Body.toString());
    } else {
      throw new Error("No data found or data is inaccessible.");
    }
  } catch (error) {
    console.error("Error reading JSON file: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const getTableDataFromCsv = async (
  bucketName: string,
  key: string,
  numRows: number = 100
): Promise<{ columns: string[]; data: any[] }> => {
  try {
    const url = await getDownloadUrl(bucketName, key);
    let totalRows = 0;
    let position = 0;
    const chunkSize = 1024 * 20; // Start with 10KB;
    let rows: any[] = [];

    while (totalRows < 5) {
      const headers = new Headers();
      headers.append("Range", `bytes=${position}-${position + chunkSize - 1}`);
      const response = await fetch(url, { headers });
      const csvText = await response.text();
      const partialData = parse(csvText, { header: totalRows === 0 });
      rows.push(...partialData.data);
      totalRows = rows.length;
      if (partialData.data.length > 0) {
        position += chunkSize;
      } else {
        break; // Break if we read a chunk that contains no data
      }
    }

    if (rows.length > 0) {
      const tableData = rows.slice(0, numRows);
      const tableHeaderColumns = Object.keys(rows[0]);
      return { columns: tableHeaderColumns, data: tableData };
    }
    throw new Error("No data found in the CSV.");
  } catch (error) {
    console.error("Failed to download or parse CSV:", error);
    throw error; // Re-throw error to be handled by the caller
  }
};

// ------------------------------------------------------------------------------------
export const listFolders = async (
  bucketName: string,
  pathPrefix: string
): Promise<string[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: pathPrefix + "/",
    Delimiter: "/", // Use delimiter to group objects by folder
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const folders =
      data.CommonPrefixes?.map((prefix) => prefix.Prefix || "") || [];
    return folders;
  } catch (error) {
    console.error("Error listing folders: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const listFolder = async (
  bucketName: string,
  pathPrefix: string
): Promise<string[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: pathPrefix + "/",
    Delimiter: "/", // Use delimiter to group objects by folder
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const files = data.Contents?.map((prefix) => prefix.Key || "") || [];
    return files;
  } catch (error) {
    console.error("Error listing files: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const downloadS3FolderAsZip = async (bucket: string, prefix: string) => {
  try {
    const listParams = {
      Bucket: bucket,
      Prefix: prefix,
    };

    const listedObjects = await s3.listObjectsV2(listParams).promise();

    if (!listedObjects.Contents || listedObjects.Contents.length === 0) {
      console.log("No files found in the specified folder.");
      return;
    }

    const zip = new JSZip();

    const filePromises = listedObjects.Contents.map(async (file) => {
      if (file.Key) {
        const data = await s3
          .getObject({
            Bucket: bucket,
            Key: file.Key,
          })
          .promise();

        if (data.Body) {
          zip.file(file.Key.replace(prefix, ""), data.Body as Blob);
        }
      }
    });

    await Promise.all(filePromises);

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `${prefix.replace(/\//g, "_")}.zip`);
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
